import { IonPage, IonContent } from "@ionic/react";

import "./WebSign.scss";
import Toolbar from "../components/Toolbar";
import { t } from "i18next";
import { RouteComponentProps } from "react-router";

import { useEffect, useState, useCallback } from "react";

interface ParamsDetails
  extends RouteComponentProps<{
    type: string;
    id: string;
  }> {}

const QuestionnaireReport: React.FC<ParamsDetails> = ({ match }) => {
  const [reportHtml, setReportHtml] = useState<string>("");

  const fetchReport = useCallback(async () => {
    const urlPath = `${localStorage.getItem("server_url")}/api/questionnaire/${match.params.type}/${match.params.id}/`;

    try {
      const response = await fetch(urlPath, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      });

      let html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      // make it so we post to our backend when we decide to save
      const form = doc.getElementById("questionnaire-form");
      if (form) {
        form.setAttribute("method", "POST");
        form.setAttribute("action", urlPath);
      }

      html = new XMLSerializer().serializeToString(doc);

      setReportHtml(html);
    } catch (error) {
      console.error("Error: ", error);
    }
  }, [match.params.type, match.params.id]);

  const injectScript = useCallback(() => {
    // script that injects and executes to make the form AJAX like,
    // as well as authenticate the user
    const script = document.createElement("script");
    script.textContent = `
        (function() {
          var accessToken = "${localStorage.getItem("access")}";

          function interceptForm() {
            var form = document.getElementById('questionnaire-form');
            if (form) {
              form.addEventListener('submit', function(e) {
                e.preventDefault();
                var formData = new FormData(form);

                var xhr = new XMLHttpRequest();
                xhr.open(form.method, form.action, true);
                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);

                xhr.onload = function() {
                  var responseDiv = document.getElementById('response-div');
                  if (!responseDiv) {
                    responseDiv = document.createElement('div');
                    responseDiv.id = 'response-div';
                    form.parentNode.insertBefore(responseDiv, form.nextSibling);
                  }
                  responseDiv.innerHTML = '<h3>Response Status: ' + xhr.status + '</h3><div>' + xhr.responseText + '</div>';

                  // Scroll to the response div
                  responseDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
                };

                xhr.onerror = function() {
                  console.error('Error:', xhr.status);
                };

                xhr.send(formData);
              });
            }
          }

          // Initial interception
          interceptForm();
        })();
      `;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    fetchReport().then(() => {
      // Wait for the next tick to ensure the HTML has been rendered
      setTimeout(injectScript, 0);
    });
  }, [fetchReport, injectScript]);

  return (
    <IonPage>
      <Toolbar title={t("Cuestionario")} />
      <IonContent fullscreen>
        <div
          // @ts-ignore
          sandbox="allow-scripts allow-forms"
          style={{ height: "100%" }}
          dangerouslySetInnerHTML={{ __html: reportHtml }}
        ></div>
      </IonContent>
    </IonPage>
  );
};

export default QuestionnaireReport;
